import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers';
import reduxThunk from 'redux-thunk';

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(reduxThunk)
});

export default store;
