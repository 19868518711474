/*
 * @Descripttion: your project
 * @Author: QI
 * @Date: 2023-12-05 11:14:01
 * @LastEditors: QI
 * @LastEditTime: 2024-04-29 13:17:19
 */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from "./store/index.js"
import './utils/rem.js'
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter } from 'react-router-dom'
import Loading from "./static/Loading/Loading.js"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
