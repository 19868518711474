import Cookies from 'js-cookie'

const TokenKey = 'access_token'
const refreshKey='refresh_token'
const userKey = 'userinfo'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(refreshKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function setRefreshToken(token) {
  return Cookies.set(refreshKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function removeRefreshToken() {
  return Cookies.remove(refreshKey)
}

export function setUserInfos(userinfo) {
  return localStorage.setItem(userKey, JSON.stringify(userinfo))
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem(userKey))
}

export function removeUserInfo() {
  localStorage.removeItem(userKey)
}