/*
 * @Descripttion: your project
 * @Author: QI
 * @Date: 2024-04-17 13:23:34
 * @LastEditors: QI
 * @LastEditTime: 2024-04-29 13:40:18
 */
import React, { Component } from 'react'
import routes from './router/config.js'
import RouteView from "./router/index";
import intl from 'react-intl-universal';
const locales = {
  "en": require('./locales/en-US.json'),
  "zh": require('./locales/zh-CN.json'),
};
export default class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      initDone: false,
    }
  }
  componentWillMount() {
    const consumerArr = ['/consumer/smartshelf', '/consumer/sensorTech', '/consumer/sensorNew']
    const businessArr = ['/business/smartshelf', '/business/sensorTech', '/business/analyzer', '/business/sensorNew']
    if (businessArr.indexOf(window.location.pathname) >= 0) {
      const data = window.location.search ? window.location.search.split('?')[1] : null
      if (data) {
        const datas = JSON.parse(window.atob(data))
        const langs = datas.lang
        if (langs) {
          localStorage.setItem('locale', langs)
          this.loadLocales();
        }
      }
    } else if (consumerArr.indexOf(window.location.pathname) >= 0) {
      this.sendMessage('getLanguage')
      window.getLanguage = (data) => {
        if (JSON.parse(data).language) {
          localStorage.setItem('locale', JSON.parse(data).language)
          this.loadLocales();
        }
      }
    }
    const lang = (navigator.language || navigator.browserLanguage).toLowerCase();
    if (lang.indexOf('zh') >= 0) {
      // 假如浏览器语言是中文
      localStorage.setItem("defaultLng", "zh")
    } else {
      // 假如浏览器语言是其它语言
      localStorage.setItem("defaultLng", "en")
    }


  }
  sendMessage = (message) => {
    window.getMessage?.postMessage(message)
  }
  loadLocales() {
    intl.init({
      currentLocale: localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh',
      locales,
    })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  render() {
    return (
      this.state.initDone &&
      <RouteView routes={routes}></RouteView>
    )
  }

}

