/*
 * @Descripttion: your project
 * @Author: QI
 * @Date: 2023-12-05 11:14:01
 * @LastEditors: QI
 * @LastEditTime: 2024-04-29 13:33:11
 */
import React from 'react'
import './index.css'

export default function Loading () {
  return (
    <div className='.loadingBox'>
      <div id="preloader_1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}