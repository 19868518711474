import React, { lazy } from "react"
import { createFromIconfontCN } from '@ant-design/icons';
const Dashboard = lazy(() => import('../pages/dashboard/index.js')); //dashboard
const Monitor = lazy(() => import('../pages/consumerSmart/monitor/index.js')); //monitor
const Light = lazy(() => import('../pages/consumerSmart/light/index.js')); //light
const BusinessSmart = lazy(() => import('../pages/businessSmart/index.js')); //light
const Sensor = lazy(() => import('../pages/consumerSensor/index.js'));//sensor
const WebSensor = lazy(() => import('../pages/businessSensor/index.js'));
const BusinessAnalyzer = lazy(() => import('../pages/businessAnalyzer/index.js'));
const WebSensorNew = lazy(() => import('../pages/businessSensorNew/index.js'))
const SensorNew=lazy(() => import('../pages/consumerSensorNew/index.js'))
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/c/font_2694222_vfu99up8h1b.js', // icon-javascript, icon-java, icon-shoppingcart (overrided)
    ],
});
const routes = [
    {
        path: '/dashboard',
        component: Dashboard
    },
    {
        path: '/consumer/smartshelf',
        component: Monitor
    },
    {
        path: '/consumer/sensorTech',
        component: Sensor
    },
    {
        path: '/consumer/sensorNew',
        component: SensorNew
    },
    {
        path: '/business/sensorTech',
        component: WebSensor
    },
    {
        path: '/business/sensorNew',
        component: WebSensorNew
    },
    {
        path: '/business/smartshelf',
        component: BusinessSmart
    },
    {
        path: '/business/analyzer',
        component: BusinessAnalyzer
    },
    {
        path: '/light',
        component: Light
    }
]
export default routes