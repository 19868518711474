import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useHistory } from "react-router-dom"
import intl from 'react-intl-universal';
const RouterView = (props) => {
    const history = useHistory();
    const { routes } = props;
    useEffect(() => {
  
    }, [history.location.pathname])
    return <Switch>

        {
            routes.map((item) =>
                 <Route
                    key={item.path}
                    path={item.path}
                    render={(History) => {
                        document.title = item.title ? intl.get(item.title.props.intlkey) : intl.get('monitor');
                        return <item.component routes={item.children ? item.children : []} {...History}>
                            <RouterView routes={item.children ? item.children : ''}></RouterView>
                        </item.component>
                    }
                    }
                />
            )
        }
         <Redirect from='/' to='/dashboard' exact />
         <Redirect to='/404' />
    </Switch>
}
export default RouterView